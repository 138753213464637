<template>
  <v-card>
    <v-card-title class="text-h6">{{
      $t("labels.remove_handover_order")
    }}</v-card-title>
    <v-card-text>{{ $t("messages.remove_handover_order") }}</v-card-text>

    <v-card-text>
      <input-qr-scan-model
        v-model="confirm_code"
        ref="codeRef"
        :label="$t('labels.tracking')"
        @keyupEnter="confirm"
      ></input-qr-scan-model>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "HandoverRemove",
  components: {},
  props: {
    codes: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    confirm_code: null,
  }),
  watch: {
    codes() {
      if (this.codes.length === 0) {
        this.cancel();
      }
    },
  },
  mounted() {
    this.clearAll();
  },
  methods: {
    clearAll() {
      setTimeout(() => {
        this.confirm_code = null;
        this.$refs.codeRef.focusInput();
      }, 100);
    },
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      if (!this.confirm_code) {
        document.getElementById("error_sound_player").play();
        return false;
      }

      const code = this.confirm_code.toUpperCase();
      if (!this.codes.includes(code)) {
        this.confirm_code = null;
        this.$vToastify.error(this.$t("messages.not_find_in_handover_list"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.$emit("confirm", code);
      this.clearAll();
    },
  },
};
</script>

<style scoped></style>
